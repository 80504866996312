'use client';
import { FieldHookConfig, useField } from 'formik';
import { Input } from '@/components/shared/material';
import { InputProps } from '@material-tailwind/react';

export const NewsletterEmailField = ({
  ...props
}: FieldHookConfig<any> & InputProps) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Input
        crossOrigin={true}
        {...field}
        icon={props.icon}
        maxLength={256}
        variant='standard'
        disabled={props.disabled}
        containerProps={props.containerProps}
        label={props.label}
        labelProps={props.labelProps}
        name={props.name}
        error={meta.touched && !!meta.error}
        required={props.required}
        placeholder={props.placeholder}
        type={props.type}
        className={`placeholder:opacity-0 focus:placeholder:opacity-100 ${props.className}`}
      />
      {meta.touched && meta.error ? (
        <div className='text-xs text-red-500'>{meta.error}</div>
      ) : null}
    </>
  );
};

export default NewsletterEmailField;
