import { m } from 'framer-motion';
import { Accordion } from './MenuAccordion';
import Image from 'next/image';
import { Fragment, useEffect, useState } from 'react';
import { IKButton } from '@/components/shared/material';
import { MenuItemLink } from '@/types/types';
import IKLink from '@/components/shared/material/IKLink';

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 55px 36px)`,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(30px at 55px 36px)',
    opacity: 0,
    transition: {
      delay: 0.2,
      type: 'spring',
      stiffness: 300,
      damping: 40,
    },
  },
};

export const MobileMenu = ({
  menuItems,
  loginLink,
  signUpLink,
  isNavOpen,
  closeNavbar,
}: {
  menuItems: any[];
  loginLink: MenuItemLink | undefined;
  signUpLink: MenuItemLink | undefined;
  isNavOpen: boolean;
  closeNavbar: () => void;
}) => {
  const [firstLevelOpen, setFirstLevelOpen] = useState<number | null>(null);
  const [secondLevelOpen, setSecondLevelOpen] = useState<number | null>(null);

  /**
   * Have centralised state for first level and second level accordions
   */
  const handleFirstLevelAccordionToggle = (index: number) => {
    setFirstLevelOpen(firstLevelOpen === index ? null : index);
    if (
      (firstLevelOpen !== index && firstLevelOpen !== null) ||
      firstLevelOpen === index
    ) {
      setSecondLevelOpen(null);
    }
  };

  const handleSecondLevelAccordionToggle = (index: number) => {
    setSecondLevelOpen(secondLevelOpen === index ? null : index);
  };

  const handleNavbarClose = () => {
    setFirstLevelOpen(null);
    setSecondLevelOpen(null);
    closeNavbar();
  };

  /**
   * When navbar is closed close all accordions
   */
  useEffect(() => {
    if (!isNavOpen) {
      setTimeout(() => {
        setFirstLevelOpen(null);
        setSecondLevelOpen(null);
      }, 200);
    }
  }, [isNavOpen]);

  return (
    <m.div
      className='fixed bottom-0 left-0 right-0 top-0 z-10 hidden h-[99.99vh] w-[99.99vw] overflow-auto bg-white bg-gradient-to-b from-yellow-50/30 to-[#FFF7E0] pb-48 pt-20 [@media(max-width:1080px)]:block'
      variants={sidebar}
    >
      <ul className='mt-5 divide-y divide-gray-300'>
        {menuItems.map((item, index) => (
          <Fragment key={index}>
            <li className='w-full'>
              {item.type === 'link' && (
                <IKLink
                  onClick={() => handleNavbarClose()}
                  link={item.link}
                  className='container-padding group flex items-center justify-between gap-0.5 rounded-md py-5 font-medium hover:bg-yellow-100'
                >
                  <span>{item.text}</span>
                  <span className='inline-block transition-transform duration-200 group-hover:translate-x-1'>
                    <svg
                      className='h-3 w-3'
                      xmlns='http://www.w3.org/2000/svg'
                      width='15'
                      height='14'
                      viewBox='0 0 15 14'
                      fill='none'
                    >
                      <path
                        d='M9.02541 13.6935L14.6305 8.09371C14.7218 8.01069 14.7948 7.90977 14.8447 7.79734C14.8945 7.68492 14.9203 7.56344 14.9203 7.44063C14.9203 7.31781 14.8945 7.19634 14.8447 7.08391C14.7948 6.97148 14.7218 6.87056 14.6305 6.78755L9.02541 1.18772C8.85867 1.02596 8.63959 0.927829 8.40688 0.910664C8.17418 0.8935 7.94284 0.958411 7.75378 1.09392C7.64016 1.16936 7.54552 1.26961 7.47712 1.38696C7.40873 1.50431 7.36842 1.63564 7.35927 1.77085C7.35013 1.90606 7.37241 2.04155 7.42439 2.16689C7.47637 2.29224 7.55667 2.4041 7.65911 2.49388L11.71 6.50616L1.72625 6.50616C1.4828 6.5155 1.25247 6.6179 1.08356 6.79186C0.914661 6.96582 0.820313 7.19783 0.820313 7.43923C0.820313 7.68062 0.914661 7.91263 1.08356 8.0866C1.25247 8.26056 1.4828 8.36295 1.72625 8.3723L11.7114 8.3723L7.66052 12.386C7.55808 12.4758 7.47778 12.5876 7.4258 12.713C7.37382 12.8383 7.35154 12.9738 7.36069 13.109C7.36983 13.2442 7.41015 13.3755 7.47854 13.4929C7.54693 13.6102 7.64157 13.7105 7.75519 13.7859C7.94426 13.9214 8.17559 13.9864 8.4083 13.9692C8.641 13.952 8.86009 13.8539 9.02682 13.6921L9.02541 13.6935Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                </IKLink>
              )}
              {item.type !== 'contact-us' && item.type !== 'link' && (
                <Accordion
                  aria-label='submenu'
                  onToggle={() => handleFirstLevelAccordionToggle(index)}
                  isOpen={firstLevelOpen === index}
                  item={item}
                  buttonClass='!py-5 font-medium'
                  activeClass='!border-b-transparent bg-yellow shadow-lg shadow-black/10'
                  containerClass='bg-yellow-150/40'
                >
                  {item.children &&
                    item.children.length > 0 &&
                    item.children.map((child: any, childIndex: number) => (
                      <Fragment key={childIndex}>
                        <ul className=''>
                          <li className={`flex w-full flex-col`}>
                            {child.type === 'heading' && (
                              <Accordion
                                aria-label='heading'
                                onToggle={() =>
                                  handleSecondLevelAccordionToggle(childIndex)
                                }
                                isOpen={
                                  (firstLevelOpen === index &&
                                    secondLevelOpen) === childIndex
                                }
                                item={child}
                                activeClass=''
                                containerWrapperClass='container-padding'
                              >
                                <div
                                  className={`rounded-md bg-yellow-150 px-2 py-2 ${
                                    childIndex === item.children.length - 1
                                      ? 'mb-4'
                                      : ''
                                  }`}
                                >
                                  {child.children &&
                                    child.children.length > 0 && (
                                      <ul>
                                        {child.children.map(
                                          (
                                            secondLevelChild: any,
                                            secondLevelIndex: number
                                          ) => (
                                            <Fragment key={secondLevelIndex}>
                                              <li
                                                className=''
                                                aria-label='link'
                                              >
                                                {secondLevelChild.description && (
                                                  <IKLink
                                                    onClick={() =>
                                                      handleNavbarClose()
                                                    }
                                                    className={`group flex rounded-md p-2 hover:bg-yellow-100 ${
                                                      secondLevelChild.image &&
                                                      secondLevelChild.image.url
                                                        ? 'gap-2'
                                                        : 'flex-col gap-0.5'
                                                    }`}
                                                    link={secondLevelChild.link}
                                                  >
                                                    {secondLevelChild.image &&
                                                    secondLevelChild.image
                                                      .url ? (
                                                      <>
                                                        <span className='-ml-1 block flex-shrink-0'>
                                                          <Image
                                                            width={50}
                                                            height={50}
                                                            src={
                                                              secondLevelChild
                                                                .image.url
                                                            }
                                                            alt={
                                                              secondLevelChild
                                                                .image.alt ||
                                                              'iKhokha mobile menu image'
                                                            }
                                                            placeholder='blur'
                                                            blurDataURL={
                                                              secondLevelChild
                                                                .image
                                                                .blurDataUrl
                                                            }
                                                            className={`rounded-md`}
                                                          />
                                                        </span>
                                                        <span className='flex flex-col gap-0.5'>
                                                          <span>
                                                            {
                                                              secondLevelChild.text
                                                            }
                                                          </span>
                                                          <span className='text-xs text-charcoal/70 transition-all duration-100 group-hover:text-charcoal-900'>
                                                            {
                                                              secondLevelChild.description
                                                            }
                                                          </span>
                                                        </span>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <span>
                                                          {
                                                            secondLevelChild.text
                                                          }
                                                        </span>
                                                        <span className='text-xs text-charcoal/70 transition-all duration-100 group-hover:text-charcoal-900'>
                                                          {
                                                            secondLevelChild.description
                                                          }
                                                        </span>
                                                      </>
                                                    )}
                                                  </IKLink>
                                                )}
                                                {!secondLevelChild.description && (
                                                  <IKLink
                                                    onClick={() =>
                                                      handleNavbarClose()
                                                    }
                                                    className='group flex items-center gap-2 rounded-md p-2 hover:bg-yellow-100'
                                                    link={secondLevelChild.link}
                                                  >
                                                    <span>
                                                      {secondLevelChild.text}
                                                    </span>
                                                    <span className='inline-block transition-transform duration-200 group-hover:translate-x-1'>
                                                      <svg
                                                        className='h-3 w-3'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='15'
                                                        height='14'
                                                        viewBox='0 0 15 14'
                                                        fill='none'
                                                      >
                                                        <path
                                                          d='M9.02541 13.6935L14.6305 8.09371C14.7218 8.01069 14.7948 7.90977 14.8447 7.79734C14.8945 7.68492 14.9203 7.56344 14.9203 7.44063C14.9203 7.31781 14.8945 7.19634 14.8447 7.08391C14.7948 6.97148 14.7218 6.87056 14.6305 6.78755L9.02541 1.18772C8.85867 1.02596 8.63959 0.927829 8.40688 0.910664C8.17418 0.8935 7.94284 0.958411 7.75378 1.09392C7.64016 1.16936 7.54552 1.26961 7.47712 1.38696C7.40873 1.50431 7.36842 1.63564 7.35927 1.77085C7.35013 1.90606 7.37241 2.04155 7.42439 2.16689C7.47637 2.29224 7.55667 2.4041 7.65911 2.49388L11.71 6.50616L1.72625 6.50616C1.4828 6.5155 1.25247 6.6179 1.08356 6.79186C0.914661 6.96582 0.820313 7.19783 0.820313 7.43923C0.820313 7.68062 0.914661 7.91263 1.08356 8.0866C1.25247 8.26056 1.4828 8.36295 1.72625 8.3723L11.7114 8.3723L7.66052 12.386C7.55808 12.4758 7.47778 12.5876 7.4258 12.713C7.37382 12.8383 7.35154 12.9738 7.36069 13.109C7.36983 13.2442 7.41015 13.3755 7.47854 13.4929C7.54693 13.6102 7.64157 13.7105 7.75519 13.7859C7.94426 13.9214 8.17559 13.9864 8.4083 13.9692C8.641 13.952 8.86009 13.8539 9.02682 13.6921L9.02541 13.6935Z'
                                                          fill='currentColor'
                                                        />
                                                      </svg>
                                                    </span>
                                                  </IKLink>
                                                )}
                                              </li>
                                            </Fragment>
                                          )
                                        )}
                                      </ul>
                                    )}
                                </div>
                              </Accordion>
                            )}
                            {child.type === 'link' && (
                              <IKLink
                                onClick={() => handleNavbarClose()}
                                link={child.link}
                                className='container-padding group flex flex-col gap-0.5 rounded-md py-2 hover:bg-yellow-100'
                              >
                                <span>{child.text}</span>
                                <span className='text-xs text-charcoal/70 transition-all duration-100 group-hover:text-charcoal-900'>
                                  {child.description}
                                </span>
                              </IKLink>
                            )}
                          </li>
                        </ul>
                      </Fragment>
                    ))}
                </Accordion>
              )}
            </li>
          </Fragment>
        ))}
      </ul>

      <div className='container-padding fixed bottom-0 left-0 z-20 mt-auto flex w-full gap-4 bg-white py-5 [&>a]:flex-grow'>
        <IKButton
          size='sm'
          variant='outlined'
          color='charcoal'
          className='flex-grow px-5 text-base font-medium'
          link={loginLink}
        >
          Login
        </IKButton>
        <IKButton
          size='sm'
          variant='filled'
          color='charcoal'
          className='flex-grow !py-2.5 px-5 text-base'
          link={signUpLink}
        >
          <span>Sign Up</span>
        </IKButton>
      </div>
    </m.div>
  );
};
