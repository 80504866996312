'use client';
import { m } from 'framer-motion';

export const Accordion = ({
  item,
  children,
  buttonClass,
  activeClass,
  containerClass,
  isFirstTimeInteraction,
  containerWrapperClass,
  isOpen,
  onToggle,
}: {
  item: any;
  children: React.ReactNode;
  buttonClass?: string;
  activeClass?: string;
  containerClass?: string;
  isFirstTimeInteraction?: boolean;
  containerWrapperClass?: string;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  return (
    <>
      <m.button
        initial={false}
        className={`container-padding flex w-full items-center justify-between border-none py-3 text-left text-charcoal ${buttonClass} ${isOpen ? `${activeClass}` : ''}`}
        onClick={onToggle}
      >
        <span className='flex flex-col gap-0.5'>
          <span>{item.text}</span>
          {item.description && (
            <span className='text-xs text-charcoal/70 transition-all duration-100 group-hover:text-charcoal-900'>
              {item.description}
            </span>
          )}
        </span>
        <span
          className={`transform duration-150 ${isOpen ? `rotate-180` : ''}`}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='14'
            height='9'
            viewBox='0 0 14 9'
            fill='none'
          >
            <path
              d='M6.99882 5.86445L1.95048 0.816116C1.85884 0.712673 1.74473 0.631591 1.6169 0.579088C1.48906 0.526585 1.3509 0.504054 1.21301 0.513226C1.07512 0.522397 0.94116 0.563026 0.821405 0.631996C0.70165 0.700967 0.599281 0.796447 0.522148 0.911114C0.383744 1.1022 0.317366 1.33608 0.334751 1.57139C0.352136 1.80669 0.452164 2.02827 0.617149 2.19695L6.33215 7.86362C6.41692 7.95596 6.51995 8.02968 6.63472 8.08011C6.74948 8.13054 6.87346 8.15659 6.99882 8.15659C7.12417 8.15659 7.24815 8.13054 7.36292 8.08011C7.47768 8.02968 7.58071 7.95596 7.66548 7.86362L13.3805 2.19695C13.5455 2.02827 13.6455 1.80669 13.6629 1.57139C13.6803 1.33608 13.6139 1.1022 13.4755 0.911114C13.3983 0.796447 13.296 0.700967 13.1762 0.631996C13.0565 0.563026 12.9225 0.522397 12.7846 0.513226C12.6467 0.504054 12.5086 0.526585 12.3807 0.579088C12.2529 0.631591 12.1388 0.712673 12.0471 0.816116L6.99882 5.86445Z'
              fill='currentColor'
            />
          </svg>
        </span>
      </m.button>
      <div className={`${containerWrapperClass}`}>
        <m.div
          key='content'
          initial='collapsed'
          animate={isOpen ? 'open' : 'collapsed'}
          exit='collapsed'
          className={`${containerClass} overflow-hidden`}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            duration: isFirstTimeInteraction ? 0 : 0.25,
            type: 'tween',
            ease: 'easeInOut',
          }}
        >
          {children}
        </m.div>
      </div>
    </>
  );
};
