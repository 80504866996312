'use client';
import PaymentIconsSvg from '../../../../assets/images/payment-icons.svg';
import Image from 'next/image';

export default function PaymentIcons() {
  return (
    <div className='flex w-full flex-col items-center justify-center py-4 md:flex-row'>
      <div className='flex gap-4 text-blue-gray-50 sm:justify-center'>
        {/* <Image
          src={PayOnline}
          width={110}
          height={30}
          className='w-32 '
          alt='PayOnline'
        />
        <Image src={Visa} width={60} height={30} alt='Visa' className='w-32 ' />
        <Image
          src={MasterCard}
          width={110}
          height={30}
          alt='MasterCard'
          className='w-32 '
        />
        <Image
          src={EFT}
          width={18}
          height={30}
          alt='EFT'
          className='w-32 md:w-6'
        /> */}
        <Image
          src={PaymentIconsSvg}
          width={300}
          height={20}
          alt='PaymentIcons'
          className='w-full'
        />
      </div>
    </div>
  );
}
