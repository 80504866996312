import React from 'react';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import Image from 'next/image';
import { components } from '../../../sections/PortableTextComponents';

export default function Rating(props: {
  trustImage?: {
    url?: string;
    alt?: string;
    mediaAlt?: string;
    blurDataURL?: string;
  };
  trustText?: PortableTextBlock;
}) {
  const { trustImage, trustText } = props;

  return (
    <section className='relative z-10 bg-charcoal'>
      <div className='absolute left-0 top-0 h-max w-full -translate-y-[calc(100%_-_2px)] transform overflow-hidden'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-[200%] md:w-full [&_path]:fill-charcoal'
          width='1464'
          height='34'
          viewBox='0 0 1464 34'
          fill='none'
          preserveAspectRatio='none'
        >
          <path
            d='M0 34H1464V13.9567C1462.14 14.0919 1459.76 14.2755 1456.86 14.4993L1456.79 14.5044C1400.39 18.8525 1148.67 38.2577 731.531 12.6924C315.789 -12.7874 65.0374 7.19974 7.26685 11.8046C4.35205 12.0369 1.92871 12.23 0 12.3763V34Z'
            fill='none'
          />
        </svg>
      </div>

      <div className='container-padding container relative flex -translate-y-[60px] transform flex-col items-center justify-center gap-5'>
        <div className='flex w-full max-w-max justify-center rounded-3xl bg-yellow-150 px-4 py-6 md:px-14 md:py-8'>
          {trustImage && trustImage.url && (
            <Image
              width={450}
              height={50}
              src={trustImage.url}
              alt={trustImage.mediaAlt || trustImage.alt || 'App of the year!'}
              placeholder='blur'
              blurDataURL={trustImage.blurDataURL}
            />
          )}
        </div>
        <div className='max-w-lg text-center text-xs text-white lg:text-sm'>
          {trustText && (
            <PortableText
              components={components}
              value={trustText}
            ></PortableText>
          )}
        </div>
      </div>
    </section>
  );
}
