// @ts-nocheck
// ignore whole file for coverage tslint:disable

'use client';
import { useState, useEffect, useRef } from 'react';
import { getForm } from '@/lib/hubspot/services/formService';

import Copyright from './Copyright';
import PaymentIcons from './PaymentIcons';
import Categories from './Categories';
import MarketingCards from './MarketingCards';
import { Form, Settings } from '@/types/types';
import Rating from './Rating';
import { useInView } from 'framer-motion';
import { isFooterInViewAtom } from '@/store/globalStore';
import { useSetAtom } from 'jotai';

export default function FooterWrapper({
  data,
  simpleMode,
  formId,
}: {
  data: Settings;

  simpleMode?: boolean;
  formId: string;
}) {
  const footer = useRef(null);
  const isInView = useInView(footer);
  const setIsFooterInView = useSetAtom(isFooterInViewAtom);
  // const simpleMode = simpleMode ?? false;

  // Set footer in view global Jotai state
  useEffect(() => {
    setIsFooterInView(isInView);
  }, [isInView, setIsFooterInView]);

  return (
    <>
      <footer
        ref={footer}
        className='relative !z-50 w-full bg-charcoal pb-2 pt-5 text-gray-100'
      >
        <Rating
          trustImage={data?.footer?.trustImage}
          trustText={data?.footer?.trustText}
        />
        <div className='container-padding container px-8'>
          {!simpleMode && (
            <>
              <MarketingCards formId={formId} />

              {data?.footer?.menu && <Categories items={data?.footer?.menu} />}
            </>
          )}
          <PaymentIcons />

          {data?.footer?.bottomLinks && (
            <Copyright links={data?.footer?.bottomLinks} />
          )}
        </div>
      </footer>
    </>
  );
}
